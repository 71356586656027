import React, { createContext, useState, useEffect } from 'react'

const BankContext = createContext();

export function Provider({ children }) {

  const getBank = 'alsalam';

  const [bankActive, setBankActive] = useState(getBank);


  useEffect(() => {
    window.addEventListener('storage', storageEventHandler, false);

  }, []);

  function storageEventHandler(e) {
    // console.log(e);
    if (e.key === "bank") {
      // console.log(localStorage.getItem('bank'));
      setBankActive(localStorage.getItem('bank') == 'bna' || localStorage.getItem('bank') == 'alsalam' ? localStorage.getItem('bank') : localStorage.setItem('bank', 'alsalam'))
    }
  }


  function ALSALAM(MT, DUREE) {

    var Settlement = [];
    Settlement.round = function (value, precision, mode) {
      // helper variables
      var m, f, isHalf, sgn;
      // making sure precision is integer
      precision |= 0;
      m = Math.pow(10, precision);
      value *= m;
      // sign of the number
      sgn = (value > 0) | -(value < 0);
      isHalf = value % 1 === 0.5 * sgn;
      f = Math.floor(value);
      if (isHalf) {
        switch (mode) {
          // PHP_ROUND_HALF_DOWN = 2
          case 2:
            // rounds .5 toward zero
            value = f + (sgn < 0);
            break;
          // PHP_ROUND_HALF_EVEN = 3
          case 3:
            // rouds .5 towards the next even integer
            value = f + (f % 2 * sgn);
            break;
          // PHP_ROUND_HALF_ODD = 4
          case 4:
            // rounds .5 towards the next odd integer
            value = f + !(f % 2);
            break;
          default:
            // rounds .5 away from zero
            value = f + (sgn > 0);
        }
      }
      return (isHalf ? value : Math.round(value)) / m;
    }
    function simulation_aslambanque(mt, duree) {
      var b1 = mt;
      // P = principal
      var b3 = duree - 1;
      //N = number of payments
      var b7 = 0.1030;
      // RA  ols 0.12R
      var b6 = b7 * 1.19;
      //RATE in TTC
      var b4 = b6 / 12;
      //r = rate expressed per repayment period (eg. contract rate/4 for quarterly)
      var b2 = b1 * b4 * 2;
      //I = interest amount due in first payment
      var d1 = b1 + b2;
      var d2 = b4 + 1;
      var d3 = b3 - 1;
      //    =(((D1*(D2^D3)))*B4)/((D2^B3)-1)
      var e2 = d1 * Math.pow(d2, d3) * b4 / (Math.pow(d2, b3) - 1);
      return Settlement.round(e2, 2, 1);
    }

    return simulation_aslambanque(MT, DUREE)

  }

  function BNA(MF, DUREE, BNA) {

    var Settlement = [];
    Settlement.round = function (value, precision, mode) {
      // helper variables
      var m, f, isHalf, sgn;
      // making sure precision is integer
      precision |= 0;
      m = Math.pow(10, precision);
      value *= m;
      // sign of the number
      sgn = (value > 0) | -(value < 0);
      isHalf = value % 1 === 0.5 * sgn;
      f = Math.floor(value);
      if (isHalf) {
        switch (mode) {
          // PHP_ROUND_HALF_DOWN = 2
          case 2:
            // rounds .5 toward zero
            value = f + (sgn < 0);
            break;
          // PHP_ROUND_HALF_EVEN = 3
          case 3:
            // rouds .5 towards the next even integer
            value = f + (f % 2 * sgn);
            break;
          // PHP_ROUND_HALF_ODD = 4
          case 4:
            // rounds .5 towards the next odd integer
            value = f + !(f % 2);
            break;
          default:
            // rounds .5 away from zero
            value = f + (sgn > 0);
        }
      }
      return (isHalf ? value : Math.round(value)) / m;
    }


    function truncNumber(number, places) {
      var strN = number.toString();
      strN = strN.split(".")
      var num = strN[0];
      var frac = strN[1];
      num += places > 0 && frac ? "." + frac.substr(0, places) : "";
      return parseFloat(num);
    }
    function simulation_bnabanque_plus(montantFin, duree, bna) {
      duree = duree - 2;
      var formul1, formul2, tauxMarge, compteur;
      if (bna == 1) {
        formul1 = 8.25 / 12 / 100;
      }
      if (bna == 2) {
        formul1 = 9.25 / 12 / 100;
      }
      formul2 = Math.pow(1 + formul1, duree) / (Math.pow(1 + formul1, duree) - 1);
      var mensualiteHT = montantFin * formul1 * formul2;
      if (bna == 1) {
        tauxMarge = 8.25;
      }
      if (bna == 2) {
        tauxMarge = 9.25;
      }
      var capitrestant = montantFin;
      var mntEchea = mensualiteHT;
      var totalMrg = 0;
      var diff = capitrestant * (tauxMarge * 30 / (360 * 100)) * 2;
      //2 mois
      for (compteur = 1; compteur <= duree; compteur++) {
        var mntMrg_echea = capitrestant * tauxMarge * 30 / (360 * 100);
        var partCap_remb = mntEchea - mntMrg_echea;
        capitrestant = capitrestant - partCap_remb;
        totalMrg = totalMrg + mntMrg_echea;
      }
      totalMrg = totalMrg + diff;
      var mntTVA = totalMrg * 0.19;
      var MntRemHt = montantFin + totalMrg;
      var MntRembTTC = MntRemHt + mntTVA;
      var MensTTC = MntRembTTC / duree;
      // $MntRembTTC=round($MntRembTTC,2,PHP_ROUND_HALF_UP);
      MntRembTTC = truncNumber(MntRembTTC, 2);
      MensTTC = Settlement.round(MensTTC, 2, 1);
      mntTVA = Settlement.round(mntTVA, 2, 1);
      mntTVA = truncNumber(mntTVA, 2);
      //$mntTVA=round($mntTVA,2,PHP_ROUND_HALF_UP);
      totalMrg = Settlement.round(totalMrg, 2, 1);
      mntEchea = Settlement.round(mntEchea, 2, 1);
      MntRemHt = Settlement.round(MntRemHt, 2, 1);
      var a = { 'mntTVA': mntTVA, 'MntRemHt': MntRemHt, 'mntEchea': mntEchea, 'MntRembTTC': MntRembTTC, 'MensTTC': MensTTC, 'totalMrg': totalMrg };
      return a;
    }

    return simulation_bnabanque_plus(MF, DUREE, BNA)

  }


  const simulationBnabanquePlus = (montantFin, duree, bna) => {
    let formul1;
    if (bna === 1) {
        formul1 = 8.25 / 12 / 100;
    } else if (bna === 2) {
        formul1 = 9.25 / 12 / 100;
    }

    const formul2 = Math.pow((1 + formul1), duree) / (Math.pow((1 + formul1), duree) - 1);
    const mensualiteHT = montantFin * formul1 * formul2;

    const tauxMarge = bna === 1 ? 8.25 : 9.25;

    let capitrestant = montantFin;
    const mntEchea = mensualiteHT;
    let totalMrg = 0;

    for (let compteur = 1; compteur <= duree; compteur++) {
        const mntMrg_echea = capitrestant * tauxMarge * 30 / (360 * 100);
        const partCap_remb = mntEchea - mntMrg_echea;
        capitrestant -= partCap_remb;
        totalMrg += mntMrg_echea;
    }

    const mntTVA = totalMrg * 0.19;

    const MntRemHt = montantFin + totalMrg;
    const MntRembTTC = MntRemHt + mntTVA;
    const MensTTC = MntRembTTC / duree;

    const truncNumber = (num, decimals) => {
        const factor = Math.pow(10, decimals);
        return Math.trunc(num * factor) / factor;
    };

    const roundedResults = {
        mntTVA: truncNumber(mntTVA, 2),
        MntRemHt: Math.round(MntRemHt * 100) / 100,
        mntEchea: Math.round(mntEchea * 100) / 100,
        MntRembTTC: truncNumber(MntRembTTC, 2),
        MensTTC: Math.round(MensTTC * 100) / 100,
        totalMrg: Math.round(totalMrg * 100) / 100
    };

    return roundedResults;
};

// Example usage:
// const result = simulationBnabanquePlus(100000, 12, 1);
// console.log(result);


function AlbarakaSimulation(Price, Month,type = 1) {

  let duree = Month;
  let prix_achat = Price;
  let taux_An= type == 1 ? 8.5 : 10.03;
  let tva = 19;
  let taux_mois = taux_An * (1+(tva/100))/100/12;
  
  //  Start of Calculate Mensualité -----------------------------------------------

  function calculateMensualite(Taux_Mois, duree, prix_achat) {
      if (Taux_Mois === 0) return -prix_achat / duree;
      return -(Taux_Mois * prix_achat) / (1 - Math.pow(1 + Taux_Mois, -duree));
  }
  const Mensualite_Montant = Math.abs(calculateMensualite(taux_mois, duree,prix_achat))?.toFixed(2);
  
  //  End of Calculate Mensualité -----------------------------------------------
  
  
  // Result ------------------------------------------------

  const Result = {
    MensTTC : Mensualite_Montant || 0
  };

   return Result;
  
}

function AlbarakaMaxFinancement(salary,duree,type = 1) {

// let salary = 80000;
// let duree = 33;
let dureeMax = 60;
let salary_30 = salary * 0.30
let Financement_max_selon_conv = type == 1 ? 1000000.00 : 3500000.00;
let prix_achat =  type == 1 ? 1000000 : 3500000;
let apport_min = type == 1 ? 0 : 10;
let B33 = 100 - apport_min;
let taux_An= type == 1 ? 8.5 : 10.03;
let tva = 19;
let taux_mois = taux_An * (1+(tva/100))/100/12;


//  Start of Calculate Financement Max suivant salaire ------------------------------------

function Calculate_FinancementMax_suivant_salaire(salary, duree, dureeMax, salary_30) {
  if (salary === "" || duree > dureeMax) {
    return 0;
  }

  function calculatePV(taux_mois, duree, salary_30, fv = 0) {
    return -(salary_30 / taux_mois) * (1 - Math.pow(1 + taux_mois, -duree)) - fv;
  }
  return calculatePV(taux_mois, duree, salary_30);
}

const FinancementMax_suivant_salaire_Montant = Math.abs(Calculate_FinancementMax_suivant_salaire(salary, duree, dureeMax, salary_30)).toFixed(2);

//  End of Calculate Financement Max suivant salaire ---------------------------------------


//  Start of Calculate Maximum suivant prix ------------------------------------------

function Calculate_Maximum_suivant_prix(salary, duree, dureeMax, prix_achat, B33) {
    if (salary === "" || duree > dureeMax) {
        return 0;  
    } else {
        return prix_achat * (B33 / 100); 
    }
}

let Calculate_Maximum_suivant_prix_Monatnt = Calculate_Maximum_suivant_prix(salary, duree, dureeMax, prix_achat, B33);

//  End of Calculate Maximum suivant prix -----------------------------------------------



//  Start of Calculate Financement possible -----------------------------------------------

let Financement_Max_suivant_salaire = FinancementMax_suivant_salaire_Montant;
let Maximum_suivant_prix = Calculate_Maximum_suivant_prix_Monatnt;

function Financement_possible(salary, duree, dureeMax, Financement_Max_suivant_salaire, Maximum_suivant_prix, Financement_max_selon_conv) {
    if (salary === "" || duree > dureeMax) {
        return 0;
    } else {
        if (Financement_Max_suivant_salaire < Maximum_suivant_prix && Financement_Max_suivant_salaire > Financement_max_selon_conv) {
            return Financement_max_selon_conv;
        } else if (Financement_Max_suivant_salaire > Maximum_suivant_prix && Financement_Max_suivant_salaire > Financement_max_selon_conv && Maximum_suivant_prix > Financement_max_selon_conv) {
            return Financement_max_selon_conv;
        } else if (Financement_Max_suivant_salaire > Maximum_suivant_prix && Financement_Max_suivant_salaire > Financement_max_selon_conv && Maximum_suivant_prix < Financement_max_selon_conv) {
            return Maximum_suivant_prix;
        } else if ((Financement_Max_suivant_salaire - Maximum_suivant_prix) < 0 && (Financement_Max_suivant_salaire - Financement_max_selon_conv) < 0) {
            return Financement_Max_suivant_salaire;
        } else {
            return Maximum_suivant_prix;
        }
    }
}
let Financement_possible_Montant = Financement_possible(salary, duree, dureeMax, Financement_Max_suivant_salaire, Maximum_suivant_prix, Financement_max_selon_conv);

//  End of Calculate Financement possible -----------------------------------------------

 // Result ------------------------------------------------

 const Result = {
  max : Math.floor(Financement_possible_Montant || 0),
  min : type == 1 ? 50000 : 100000
};

 return Result;

  
}

function BeaMaxFinancement(price,duration,income) {
  
  const annualRate = 7.5; // Annual interest rate in %
  const monthlyRate = annualRate / 100 / 12;  // Monthly interest rate
  const minDownPayment = price * 0.10; // Minimum personal contribution
  const maxFinancing = Math.min(price * 0.90, 1500000); // Maximum financing limit (90% of the price or 1.5 million DA)

  // Calculate the maximum monthly payment the client can afford (30% of income)
  const maxMonthlyPayment = income * 0.30;
  
  // Calculate the maximum loan that can be afforded based on the 30% rule
  let maxLoan = maxMonthlyPayment * (1 - Math.pow(1 + monthlyRate, -duration)) / monthlyRate;

  // The final loan amount is the minimum of these two constraints
  const financingAmount = Math.min(maxLoan, maxFinancing);
 

  // Return the results with properly formatted values
  return {
      max: Math.floor(financingAmount || 0),
      min: Math.round(minDownPayment)
  };
}

function BeaSimulation(price, duration) {

  const annualRate = 7.5; // Annual interest rate in %
  const monthlyRate = annualRate / 100 / 12;  // Monthly interest rate
  const minDownPayment = price * 0.10; // Minimum personal contribution
  const maxFinancing = Math.min(price * 0.90, 1500000); // Maximum financing limit (90% of the price or 1.5 million DA)

  // Calculate the financing amount (either 90% of the price or the max limit)
  const financingAmount = Math.min(price - minDownPayment, maxFinancing);

  // Calculate the monthly payment for the selected financing amount using the loan formula
  const monthlyPayment = financingAmount * monthlyRate / (1 - Math.pow(1 + monthlyRate, -duration));

  // Return the result with the formatted monthly payment
  return {
      MensTTC: monthlyPayment?.toFixed(2)
  };
}


  function GetTaksitPrice(Price, Month) {

    if (bankActive === 'alsalam') {

      return {
        12: Math.round(ALSALAM(Price, 12)),
        24: Math.round(ALSALAM(Price, 24)),
        36: Math.round(ALSALAM(Price, 36)),
        "default": Math.round(ALSALAM(Price, 60)),
        getallprice: Math.round(ALSALAM(Price, Month))
      }

    } else if(bankActive === 'bna') {

      return {
        12: Math.round(simulationBnabanquePlus(Price, 12, 2)['MensTTC']),
        24: Math.round(simulationBnabanquePlus(Price, 24, 2)['MensTTC']),
        36: Math.round(simulationBnabanquePlus(Price, 36, 2)['MensTTC']),
        "default": Math.round(simulationBnabanquePlus(Price, 60, 2)['MensTTC']),
        getallprice: Math.round(simulationBnabanquePlus(Price, Month, 2)['MensTTC'])
      }

    }else if(bankActive === 'albaraka') {

      return {
        12: Math.round(AlbarakaSimulation(Price, 12)['MensTTC']),
        24: Math.round(AlbarakaSimulation(Price, 24)['MensTTC']),
        36: Math.round(AlbarakaSimulation(Price, 36)['MensTTC']),
        "default": Math.round(AlbarakaSimulation(Price, 60)['MensTTC']),
        getallprice: Math.round(AlbarakaSimulation(Price, Month)['MensTTC'])
      }

    }else if(bankActive === 'bea') {

      return {
        12: Math.round(BeaSimulation(Price, 12)['MensTTC']),
        24: Math.round(BeaSimulation(Price, 24)['MensTTC']),
        36: Math.round(BeaSimulation(Price, 36)['MensTTC']),
        "default": Math.round(BeaSimulation(Price, 60)['MensTTC']),
        getallprice: Math.round(BeaSimulation(Price, Month)['MensTTC'])
      }

    }else {

      return {
        12: Math.round(ALSALAM(Price, 12)),
        24: Math.round(ALSALAM(Price, 24)),
        36: Math.round(ALSALAM(Price, 36)),
        "default": Math.round(ALSALAM(Price, 60)),
        getallprice: Math.round(ALSALAM(Price, Month))
      }

    }

  }

  // console.log(GetTaksitPrice(69938));

  const BankData = {
    'alsalam' : {'code' : '01', 'max' : '60' , 'duree' :['60','48','36','24','12'] , min_salary : 25},
    'bna' : {'code' : '02', 'max' : '60' , 'duree' :['60','48','36','24','12'] , min_salary : 20},
    'albaraka' : {'code' : '05', 'max' : '60' , 'duree' :['60','48','36','24','12'] , min_salary : 20},
    'bea' : {'code' : '06', 'max' : '60' , 'duree' :['60','48','36','24','12'] , min_salary : 25},

  }

  return <BankContext.Provider value={{ bankActive, setBankActive, GetTaksitPrice,BankData ,AlbarakaMaxFinancement,BeaMaxFinancement}}>{children}</BankContext.Provider>
}


export default BankContext